import { Country, ParcelSize } from '../graphql/generated/types';

//todo: use this one from GraphQL
export enum Language {
  CS = 'cs',
  EN = 'en',
}
export type Range = [number, number];

/** returns from specific cfg node required subkey TKey or 'default' if key is missing. */
export const getSpecificCfg = <TKey extends string | number | symbol, TValue>(
  node: SpecificCfgNode<TKey, TValue>,
  key: TKey,
  fallbackValue?: TValue,
) => {
  if (!key) throw new Error('Invalid cfg key');
  if (!node && fallbackValue !== undefined) return fallbackValue;
  return node[key] ?? node.default;
};

export type SpecificCfgNode<
  TKey extends string | number | symbol,
  TValue
> = Partial<Record<TKey, TValue>> & {
  default: TValue;
};
type ParcelSpecA = {
  iconZoom: number;
  dimensions?: null | undefined;
};

type ParcelSpecB = {
  /** x/y/z OR maxLength/circumference */
  dimensions: [number, number, number] | [number, number];
  iconZoom?: number;
};

export type ParcelSpecs = {
  minWeight: number;
  maxWeight: number;
  ranges?: Range[];
} & (ParcelSpecA | ParcelSpecB);

export enum EstimateDeliveryMethod {
  COMPUTE = 'compute',
  ESTIMATE = 'estimate',
}

export type ParcelSpecsList = Record<ParcelSize, ParcelSpecs>;

export type PricingListType = {
  courierPickUpShipment: number;
  courierDeliveryShipment: number;
  cashOnDelivery: number;
  anotherCountry: number;
  extraInsuranceRatio: number;
  /** price as a number or array when ranges are specified */
  priceBase: Record<ParcelSize, number | number[]>;
};

type EstimateDelivery =
  | {
      method: 'estimate';
      days: number | [number, number];
    }
  | {
      method: 'compute';

      days: number;
    };

type FeaturesType = Record<
  | 'multiPackage'
  | 'v3'
  | 'sourceCountry'
  | 'parcelShopsMapIntegration'
  | 'allowLockers'
  | 'allowDisabledShops',
  boolean
>;

type ConstraintsType = {
  maxCircumference: number;
  maxCircumferenceParcelShop: number;
  maxLength: number;
  maxLengthParcelShop: number;
  maxWeight: number;
  maxWeightParcelShop: number;
  maxInsurance: number;
  maxOptionalInsurance: number;
  minCashOnDelivery: SpecificCfgNode<Country, number>;
  maxCashOnDelivery: SpecificCfgNode<Country, number>;
  maxTotalPriceWithVat: number;
  paymentWaitingTimeoutMs: number;
  estimateDelivery: SpecificCfgNode<Country, EstimateDelivery>;
  maxItemCountInOrder: number;
  deliveryParcelShops: SpecificCfgNode<Country, boolean>;
  deliveryCourier: SpecificCfgNode<Country, boolean>;
};

export type DefaultsType = {
  parcelValue: number;
  modalAutoCloseDefaultTimeout: number;
  parcelShopsListSize: number;
  defaultParcelShopDistance?: number;
};

type ExternalLinks = Record<
  | 'dpdWeb'
  | 'faq'
  | 'howWrap'
  | 'privacyPolicy'
  | 'termsConditions'
  | 'cookies'
  | 'responsibility'
  | 'restrictedGoods'
  | 'termsForTransport',
  string
>;

type AdminSpecs = {
  configuration: {
    spacing: number | string;
  };
};

type ServerSpecs = {
  configuration: {
    configRefreshMs: number;
  };
};

type Other = {
  parcelShopSearchByAddressApiUrl: string;
  parcelShopSearchByIdApiUrl: string;
};

export type DynamicConfigRoot = {
  version: number;
  features: FeaturesType;
  priceList: SpecificCfgNode<Country, PricingListType>;
  parcelSpecifications: SpecificCfgNode<Country, ParcelSpecsList>;
  constraints: ConstraintsType;
  defaults: DefaultsType;
  externalLinks: SpecificCfgNode<Language, ExternalLinks>;
  admin: AdminSpecs;
  other: Other;
};
type TopLevelKeys = 'default' | 'campaign1' | 'campaign2';

export type DynamicConfigTopLevel = SpecificCfgNode<
  TopLevelKeys,
  DynamicConfigRoot
> & {
  server: ServerSpecs;
  configVersion: number;
};

export const iniSeedConfig: DynamicConfigTopLevel = {
  configVersion: 1,
  default: {
    version: 1,
    features: {
      multiPackage: false,
      v3: true,
      sourceCountry: false,
      parcelShopsMapIntegration: false,
      allowLockers: true,
      allowDisabledShops: false,
    },
    priceList: {
      [Country.CZ]: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 30,
        anotherCountry: 0,
        extraInsuranceRatio: 0.0025,
        priceBase: {
          S: 89,
          M: 109,
          L: 149,
          XL: [279, 319, 369],
        },
      },
      [Country.SK]: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 100,
        anotherCountry: 0,
        extraInsuranceRatio: 0.005,
        priceBase: {
          S: 169,
          M: 259,
          L: 279,
          XL: [319, 389, 439],
        },
      },
      [Country.PL]: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 100,
        anotherCountry: 0,
        extraInsuranceRatio: 0.005,
        priceBase: {
          S: 279,
          M: 419,
          L: 469,
          XL: [519, 569, 619],
        },
      },
      [Country.DE]: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 100,
        anotherCountry: 0,
        extraInsuranceRatio: 0.005,
        priceBase: {
          S: 279,
          M: 419,
          L: 469,
          XL: [519, 569, 619],
        },
      },
      [Country.AT]: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 100,
        anotherCountry: 0,
        extraInsuranceRatio: 0.005,
        priceBase: {
          S: 279,
          M: 419,
          L: 469,
          XL: [719, 789, 839],
        },
      },
      [Country.HU]: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 100,
        anotherCountry: 0,
        extraInsuranceRatio: 0.005,
        priceBase: {
          S: 279,
          M: 359,
          L: 459,
          XL: [719, 789, 839],
        },
      },
      default: {
        courierPickUpShipment: 40,
        courierDeliveryShipment: 40,
        cashOnDelivery: 100,
        anotherCountry: 0,
        extraInsuranceRatio: 0.005,
        priceBase: {
          S: 478,
          M: 724,
          L: 925,
          XL: [1065, 1172, 1193],
        },
      },
    },
    parcelSpecifications: {
      default: {
        S: {
          minWeight: 0,
          maxWeight: 3,
          dimensions: [60, 40, 15],
        },
        M: {
          minWeight: 3,
          maxWeight: 10,
          dimensions: [60, 40, 35],
        },
        L: {
          minWeight: 10,
          maxWeight: 15,
          dimensions: [56, 41, 67],
        },
        XL: {
          minWeight: 15,
          maxWeight: 31.5,
          ranges: [
            [15, 20],
            [20, 25],
            [25, 31.5],
          ],
          dimensions: [175, 300],
        },
      },
      [Country.CZ]: {
        S: {
          minWeight: 0,
          maxWeight: 3,
          dimensions: [60, 40, 15],
        },
        M: {
          minWeight: 3,
          maxWeight: 6,
          dimensions: [60, 40, 35],
        },
        L: {
          minWeight: 6,
          maxWeight: 15,
          dimensions: [56, 41, 67],
        },
        XL: {
          minWeight: 15,
          maxWeight: 31.5,
          ranges: [
            [15, 20],
            [20, 25],
            [25, 31.5],
          ],
          dimensions: [175, 300],
        },
      },
    },
    constraints: {
      maxCircumference: 300,
      maxCircumferenceParcelShop: 175,
      maxLength: 175,
      maxLengthParcelShop: 100,
      maxWeight: 31,
      maxWeightParcelShop: 15,
      maxInsurance: 50000,
      maxOptionalInsurance: 3000000,
      deliveryParcelShops: {
        [Country.CZ]: true,
        default: false,
      },
      deliveryCourier: { default: true },
      minCashOnDelivery: {
        default: 0,
        [Country.CZ]: 10,
      },
      maxCashOnDelivery: {
        default: 0,
        [Country.CZ]: 50000,
      },
      maxTotalPriceWithVat: 10000,
      paymentWaitingTimeoutMs: 45000,
      estimateDelivery: {
        default: {
          method: 'estimate',
          days: [2, 3],
        },
        [Country.CZ]: {
          method: 'compute',
          days: 1,
        },
        [Country.SK]: {
          method: 'compute',
          days: 1,
        },
      },
      maxItemCountInOrder: 10,
    },
    defaults: {
      parcelValue: 60000,
      modalAutoCloseDefaultTimeout: 3000,
      parcelShopsListSize: 40,
      defaultParcelShopDistance: 50,
    },
    externalLinks: {
      [Language.CS]: {
        dpdWeb: 'https://www.dpd.com/cz/cs/',
        faq: 'https://www.dpd.com/cz/cs/podpora/nejcastejsi-dotazy/',
        howWrap:
          'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/jak-spravne-zabalit-zasilky/',
        privacyPolicy: '/documents/Ochrana-osobnich-udaju.pdf',
        termsConditions: '/documents/Vseobecne-obchodni-podminky-01082020.pdf',
        cookies: '/documents/Cookies.pdf',
        responsibility:
          'https://www.dpd.com/cz/cs/o-nas/spolecenska-odpovednost/',
        restrictedGoods:
          'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/zbozi-vyloucene-z-prepravy/',
        termsForTransport:
          'https://www.dpd.com/cz/en/support/terms-of-transport/',
      },
      default: {
        dpdWeb: 'https://www.dpd.com/cz/en/',
        faq: 'https://www.dpd.com/cz/en/support/',
        howWrap:
          'https://www.dpd.com/cz/en/support/terms-of-transport/packing-instructions/',
        privacyPolicy: 'https://www.dpd.com/cz/en/data-protection/',
        termsConditions:
          'https://www.dpd.com/cz/en/general-terms-and-conditions/',
        cookies: 'https://www.dpd.com/cz/en/data-protection/',
        responsibility: 'https://www.dpd.com/cz/en/company/responsibility/',
        restrictedGoods:
          'https://www.dpd.com/cz/en/support/terms-of-transport/exclusions-from-shipment/',
        termsForTransport:
          'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/',
      },
    },
    admin: {
      configuration: {
        spacing: 4,
      },
    },
    other: {
      parcelShopSearchByAddressApiUrl:
        'https://stage.api.dpd.cz/api/v1/pickup-points',
      parcelShopSearchByIdApiUrl:
        'https://stage.api.dpd.cz/api/v1/pickup-points/',
    },
  },

  server: {
    configuration: {
      configRefreshMs: 60000,
    },
  },
};
